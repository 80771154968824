import { initializeApp } from "firebase/app";
import { getFirestore, collection } from "firebase/firestore";
import { getAuth } from "firebase/auth";
import { getStorage } from "firebase/storage";

const firebaseConfig = {
    apiKey: "AIzaSyDnKNXKKo-UxSoNOu25Yk-Sqs2j1Npx_u8",
    authDomain: "ba-custom-wa.firebaseapp.com",
    projectId: "ba-custom-wa",
    storageBucket: "ba-custom-wa.appspot.com",
    messagingSenderId: "571258022582",
    appId: "1:571258022582:web:e5c8efcdadaf3d3ef51417"
};

export const firebaseApp = initializeApp(firebaseConfig);
export const db = getFirestore(firebaseApp);
export const auth = getAuth(firebaseApp);

export const storage = getStorage(firebaseApp);
export const consultantsCollection = collection(db, 'consultants')
export const consultationsCollection = collection(db, 'consultations')