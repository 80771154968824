import { createStore } from 'vuex'
import {onAuthStateChanged} from "firebase/auth";
import {auth} from "@/plugins/firebase";

export default createStore({
  state: {
    user: null,
    loadingFinished: false
  },
  getters: {
  },
  mutations: {
    user: (state, user) => state.user = user,
    loadingFinished: (state, loadingFinished) => state.loadingFinished = loadingFinished
  },
  actions: {
    subscribeToAuthChanges({ commit }) {
        onAuthStateChanged(auth, (user) => {
          commit('user', user)
          commit('loadingFinished', true)
        });
    }
  },
  modules: {
  }
})
