import './plugins/fontawesome'
import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import { VueFire, VueFireAuth } from 'vuefire'
import './assets/tailwind.css'
import {firebaseApp} from "@/plugins/firebase";
import { FontAwesomeIcon } from "@/plugins/fontawesome";

store.dispatch("subscribeToAuthChanges");

createApp(App).use(store).use(router).use(VueFire, {
    firebaseApp,
    modules: [
        VueFireAuth(),
    ],
}).component("font-awesome-icon", FontAwesomeIcon).mount('#app');
