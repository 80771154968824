<template>
    <header class="bg-indigo-600">
        <nav class="mx-auto max-w-7xl px-6 lg:px-8" aria-label="Top">
            <div class="flex w-full items-center justify-between border-b border-indigo-500 py-6 lg:border-none">
                <div class="flex items-center">
                    <router-link to="/">
                        <span class="sr-only">Beratungsdokumentationen </span>
                        <img class="h-10 w-auto" src="https://tailwindui.com/img/logos/mark.svg?color=white" alt=""/>
                    </router-link>
                    <div class="ml-10 hidden space-x-8 lg:block">
                        <router-link v-for="link in navigation" :key="link.name" :to="link.href"
                                     class="text-base font-medium text-white hover:text-indigo-50">{{ link.name }}
                        </router-link>
                    </div>
                </div>
                <div class="ml-10 space-x-4">
                    <router-link to="/login" v-if="user === null"
                                 class="inline-block rounded-md border border-transparent bg-indigo-500 py-2 px-4 text-base font-medium text-white hover:bg-opacity-75">
                        Anmelden
                    </router-link>
                    <router-link to="/register" v-if="user === null"
                                 class="inline-block rounded-md border border-transparent bg-indigo-500 py-2 px-4 text-base font-medium text-white hover:bg-opacity-75">
                        Registrieren
                    </router-link>
                    <router-link to="/profile" v-if="user !== null"
                                 class="inline-block rounded-md border border-transparent bg-white py-2 px-4 text-base font-medium text-indigo-600 hover:bg-indigo-50">
                        Angemeldet <span>als {{ user?.displayName || user?.email }}</span>
                    </router-link>
                </div>
            </div>
            <div class="flex flex-wrap justify-center gap-x-6 py-4 lg:hidden">
                <a v-for="link in navigation" :key="link.name" :href="link.href"
                   class="text-base font-medium text-white hover:text-indigo-50">{{ link.name }}</a>
            </div>
        </nav>
    </header>
</template>

<script setup>
import { useCurrentUser } from "vuefire";

const user = useCurrentUser()

const navigation = [
    { name: 'Beratungen ansehen', href: '/consultations' },
    { name: 'Beratung erstellen', href: '/consultations/create' },
    { name: 'Profil', href: '/profile' },
]
</script>