import {createRouter, createWebHistory} from 'vue-router'
import HomeView from '../views/HomeView.vue'
import {getCurrentUser} from 'vuefire'

const routes = [
    {
        path: '/login',
        name: 'Login',
        component: () => import(/* webpackChunkName: "login" */ '../views/Login.vue'),
    },
     {
        path: '/register',
        name: 'Regstrieren>',
        component: () => import(/* webpackChunkName: "register" */ '../views/Register.vue'),
    },
    {
        path: "/profile",
        name: "Profile",
        component: () => import(/* webpackChunkName: "profile" */ '../views/Profile.vue'),
        meta: {
            requiresAuth: true
        }
    }, {
        path: "/consultations/create",
        name: "Create",
        component: () => import(/* webpackChunkName: "create" */ '../views/CreateConsultation.vue'),
        meta: {
            requiresAuth: true
        }
    }, {
        path: "/consultation/:id",
        name: "Consultation",
        component: () => import(/* webpackChunkName: "consultation" */ '../views/EditConsultation.vue'),
        meta: {
            requiresAuth: true
        }
    }, {
        path: "/consultations",
        name: "Consultations",
        component: () => import(/* webpackChunkName: "consultations" */ '../views/ConsultationsOverview.vue'),
        meta: {
            requiresAuth: true
        }
    }, {
        path: '/',
        name: 'home',
        redirect: "/consultations"
    },
]

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes
})

router.beforeEach(async (to) => {
    if (to.meta.requiresAuth) {
        const currentUser = await getCurrentUser()
        if (!currentUser) {
            return {
                path: '/login',
                query: {
                    redirect: to.fullPath,
                },
            }
        }
    }
})

export default router
